import * as utils from './utils'
import pace from 'pace-js-amd-fix'
import '../css/app.scss'
import '@fortawesome/fontawesome-free/js/all'
import './pages/00_all_pages'
import './jquery_passive'
// boostrap plugins
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/tooltip'

// load cookie consent if user has not yet consent
if (utils.getCookieValue('cookieconsent_status') === '') {
    import(/* webpackChunkName: "cookieconsent" */ './cookie').then((cookie) => cookie.init())
}

document.addEventListener('DOMContentLoaded', () => {
    // display progress bar
    pace.start()

    // fetch global settings then load modules that need it
    const settings = utils.getSettings().then((settings) => {
        // load choices.js dropdown (if necessary)
        let typeaheads = document.querySelectorAll('[typeahead]')
        if (typeaheads.length > 0) {
            import(/* webpackChunkName: "typeahead" */ './typeahead').then((typeahead) =>
                typeahead.init(settings, typeaheads)
            )
        }

        let placeAutocompletes = document.querySelectorAll('[gmaps-autocomplete]')
        if (placeAutocompletes.length > 0) {
            import(/* webpackChunkName: "maps" */ './maps').then((maps) => maps.init(settings))
        }

        // NOTE: wysiwyg and chosen lib are enormous libs that need to be fetch
        // try to find a more elegant way to load these libs
        if (!document.body.classList.contains('home-index')) {
            // load wysiwyg on current page (if necessary)
            if (settings.role_user === 'company' && document.querySelectorAll('[wysiwyg]').length > 0) {
                import(/* webpackChunkName: "wysiwyg" */ './wysiwyg').then((wysiwyg) => wysiwyg.init(settings))
            }
            // form utils
            if (document.querySelector('form')) {
                import(/* webpackChunkName: "formutils" */ './form').then((form) => form.init(settings))
            }
        }
    })

    if (process.env.NODE_ENV === 'production') {
        // TODO: load some module for production only
    }

    // homepage
    if (document.body.classList.contains('home-index')) {
        import(/* webpackChunkName: "homepage" */ './pages/home').then((homepage) => homepage.init())
    }
    // search engine (jobs, candidates) and pagination
    if (
        document.body.classList.contains('jobs-list') ||
        document.body.classList.contains('jobs-my_posted_jobs') ||
        document.body.classList.contains('cv_search-index') ||
        document.body.classList.contains('cv_search-index_with_job') ||
        document.body.classList.contains('cv_search-my_candidate') ||
        document.body.classList.contains('candidat-applications') ||
        document.body.classList.contains('candidat-bookmarks')
    ) {
        import(/* webpackChunkName: "search" */ './search').then((search) => search.init())
    }
    // job details
    if (document.body.classList.contains('jobs-details')) {
        import(/* webpackChunkName: "job-details" */ './pages/job').then((job) => job.init())
    }
    // espace recruteur
    if (document.body.classList.contains('home-recruteur')) {
        import(/* webpackChunkName: "home-recruteur" */ './pages/recruiter').then((recruiter) => recruiter.init())
    }
    // blog details
    if (document.body.classList.contains('blog-details')) {
        import(/* webpackChunkName: "home-recruteur" */ './pages/blog').then((blog) => blog.init())
    }
    // commander
    if (document.body.classList.contains('order-index') || document.body.classList.contains('home-recruitment')) {
        import(/* webpackChunkName: "order" */ './pages/order').then((order) => order.init())
    }
    // bookmark
    if (
        document.body.classList.contains('candidat-bookmarks') ||
        document.body.classList.contains('candidat-details') ||
        document.body.classList.contains('cv_search-my_candidate')
    ) {
        import(/* webpackChunkName: "bookmarks" */ './bookmark').then((bookmark) => bookmark.init())
    }
    // congrats pages logic
    if (document.body.classList.contains('congratulation')) {
        import(/* webpackChunkName: "congratulation" */ './congratulation').then((congratulation) =>
            congratulation.init()
        )
    }
    // settings pages logic
    if (document.body.classList.contains('security-settings')) {
        import(/* webpackChunkName: "settings" */ './setting').then((setting) => setting.init())
    }
    // file uploader
    if (document.querySelector('.dropzone-upload')) {
        import(/* webpackChunkName: "dropzone-upload" */ './upload').then((upload) => upload.init())
    }
    // flash messages
    if (document.body.contains(document.querySelector('.flashes'))) {
        import(/* webpackChunkName: "notification" */ './notification').then((notif) => notif.init())
    }
    // modal
    if (document.querySelector('.modal-dialog')) {
        import(/* webpackChunkName: "modal-dialog" */ './modal').then((modal) => modal.init())
    }
    // reveal password input
    if (document.querySelector('.input-password')) {
        utils.passReveal()
    }

    // tooltip
    if (document.querySelector('[data-toggle="tooltip"]')) {
        $('[data-toggle="tooltip"]').tooltip()
    }

    // redirect to selected language
    if (document.querySelector('.main-nav__language > select')) {
        utils.toggleLanguage()
    }

    // redirect to previous page
    if (document.querySelector('a.result-back-link')) {
        document.querySelector('a.result-back-link').addEventListener('click', () => window.history.back())
    }
})
