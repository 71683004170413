import 'slick-carousel/slick/slick'

let navbar = document.querySelector('nav.main-nav')
const onScroll = () => {
    const scroll = document.documentElement.scrollTop
    scroll > 0 ? navbar.classList.add('scrolled') : navbar.classList.remove('scrolled')
}

document.addEventListener('DOMContentLoaded', () => {
    // Main nav sticky
    if(navbar)
        window.addEventListener('scroll', onScroll)

    // Smooth scroll to anchor
    document.querySelectorAll('a[data-anchor]').forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault()

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            })
        })
    })

    // Offers slider
    $('.jobs-slider .slides').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        prevArrow: $('.jobs-slider .prev'),
        nextArrow: $('.jobs-slider .next'),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    })
})
