// determine current browser locale
export function getLocale() {
    return new URL(document.URL).pathname.substring(1, 3)
}

// extract cookie value from its name
export function getCookieValue(a) {
    var b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)')
    return b ? b.pop() : ''
}

export function getSettings() {
    return new Promise((resolve, reject) => {
        let request = new XMLHttpRequest()
        request.open('GET', window.location.origin + '/' + getLocale() + '/js/utils')
        request.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        request.setRequestHeader('Content-Type', 'application/json')
        request.onload = function () {
            if (request.status === 200) {
                let data = JSON.parse(request.responseText)
                resolve(data)
            }
        }
        request.onerror = function () {
            reject()
        }
        request.send()
    })
}

export const getClosest = (el, selector) => {
    for (; el && el !== document; el = el.parentNode) {
        if (el.matches(selector)) return el
    }
    return null
}

export const getFormBody = (array) => {
    var formBody = []
    for (var property in array) {
        var encodedKey = encodeURIComponent(property)
        var encodedValue = encodeURIComponent(array[property])
        formBody.push(encodedKey + '=' + encodedValue)
    }
    return formBody.join('&')
}

export const passReveal = () => {
    document.querySelectorAll('input.password-revealer').forEach((el) => {
        el.addEventListener('change', (e) => {
            e.target.previousElementSibling.type = e.target.checked ? 'text' : 'password'
        })
    })
}

export const toggleLanguage = () => {
    let navLanguages = document.querySelector('.main-nav__language > select')
    navLanguages.addEventListener('change', (e) => {
        let selectedOption = navLanguages.options[navLanguages.selectedIndex]
        window.location.href = selectedOption.dataset.url
    })
}

export const formatMoney = (amount, decimalCount = 2) => {
    let decimal = '.',
        thousands = ','
    if (getLocale() == 'fr') {
        decimal = ','
        thousands = ' '
    }

    try {
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount

        const negativeSign = amount < 0 ? '-' : ''

        let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
        let j = i.length > 3 ? i.length % 3 : 0

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (decimalCount
                ? decimal +
                  Math.abs(amount - i)
                      .toFixed(decimalCount)
                      .slice(2)
                : '')
        )
    } catch (e) {
        console.log(e)
    }
}
