// add passive listener to jquery to improve performance on mobile
// https://stackoverflow.com/questions/60357083/does-not-use-passive-listeners-to-improve-scrolling-performance-lighthouse-repo

jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
        this.addEventListener('touchstart', handle, { passive: !ns.includes('noPreventDefault') })
    }
}
jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
        this.addEventListener('touchmove', handle, { passive: !ns.includes('noPreventDefault') })
    }
}
jQuery.event.special.wheel = {
    setup: function (_, ns, handle) {
        this.addEventListener('wheel', handle, { passive: true })
    }
}
jQuery.event.special.mousewheel = {
    setup: function (_, ns, handle) {
        this.addEventListener('mousewheel', handle, { passive: true })
    }
}
